import React from "react";
import { Col, Container, Row } from "react-bootstrap";

export default function Contact() {
    return  <main class="full-height background d-flex flex-column  justify-content-center align-items-center">
    <div className="text-center">
      <h1>More coming soon</h1>
      <h4>Page still under construction</h4>
    </div>
  </main>
}
