import React, { useState } from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";

import Logo from "../../images/logo2.png";

export default function HeaderNav() {
  const [expanded, setExpanded] = useState(false);
  return (
    <Navbar
      collapseOnSelect
      expanded={expanded}
      expand="lg"
      className="navbar-custom"
      sticky="top"
      bg="dark"
      variant="dark"
    >
      <Container>
        <Navbar.Brand>
          <img
            src={Logo}
            width="auto"
            height="55"
            className="d-inline-block p-3"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setExpanded(expanded ? false : "expanded")}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">
            <NavLink
              end={true}
              to="/"
              onClick={() => setExpanded(false)}
              className={({ isActive }) =>
                isActive ? "activeNavItem nav-link" : "nav-link"
              }
            >
              Home
            </NavLink>
            <NavLink
              end={true}
              to="/members"
              onClick={() => setExpanded(false)}
              className={({ isActive }) =>
                isActive ? "activeNavItem nav-link" : "nav-link"
              }
            >
              Members
            </NavLink>
            <NavLink
              end={true}
              to="/concerts"
              onClick={() => setExpanded(false)}
              className={({ isActive }) =>
                isActive ? "activeNavItem nav-link" : "nav-link"
              }
            >
              Concerts
            </NavLink>
            <NavLink
              end={true}
              to="/media"
              onClick={() => setExpanded(false)}
              className={({ isActive }) =>
                isActive ? "activeNavItem nav-link" : "nav-link"
              }
            >
              Media
            </NavLink>

            <NavLink
              end={true}
              to="/contact"
              onClick={() => setExpanded(false)}
              className={({ isActive }) =>
                isActive ? "activeNavItem nav-link" : "nav-link"
              }
            >
              Contact
            </NavLink>
          </Nav>
          <Nav>
            <NavLink
              end={true}
              to="/login"
              onClick={() => setExpanded(false)}
              className={({ isActive }) =>
                isActive ? "activeNavItem nav-link" : "nav-link"
              }
            >
              Login
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
