import React from "react";
import { Route, Routes, Outlet } from "react-router-dom";

import HeaderNav from "./components/navbar/headernav";
import Home from "./pages";
import Login from "./pages/login";
import Members from "./pages/members";
import Concerts from "./pages/concerts";
import Media from "./pages/media";
import References from "./pages/references";
import Contact from "./pages/contact";
import "bootstrap/dist/css/bootstrap.css";
import "./App.css";

function NavBarRoutes() {
  return (
    <>
      <HeaderNav />
      <Outlet />
    </>
  );
}

export default function App() {
  return (
    <Routes>
      <Route element={<NavBarRoutes />}>
        <Route path={"/"} element={<Home />} />
        <Route path={"/login"} element={<Login />} />
        <Route path={"/members"} element={<Members />} />
        <Route path={"/concerts"} element={<Concerts />} />
        <Route path={"/media"} element={<Media />} />
        <Route path={"/references"} element={<References />} />
        <Route path={"/contact"} element={<Contact />} />
      </Route>
    </Routes>
  );
}
