import React from "react";
import CookieConsent, { resetCookieConsentValue } from "react-cookie-consent";

import { coockieButton, coockieContainer } from "./style";

export const CoockieContainer = () => {
  return (
    <CookieConsent
      overlay
      location={"bottom"}
      buttonText={`Begrepen!`}
      coockieName={"BFLOYD"}
      expires={365}
      style={coockieContainer}
      buttonStyle={coockieButton}
    >
      {
        "Deze website maakt gebruik van cookies om de gebruikerservaring te verbeteren."
      }
    </CookieConsent>
  );
};

export default function UseCoockies() {
  const removeCoockie = () => {
    return resetCookieConsentValue();
  };

  return {
    removeCoockie,
  };
}
