import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const notificationTypes = {
  success: "success",
  error: "error",
  info: "info",
  warn: "warn",
};

export const NotificationContainer = () => {
  let autoTimer = 2500;

  return (
    <ToastContainer
      position="top-right"
      autoClose={autoTimer}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover={false}
    />
  );
};

export default function UseNotifcations() {
  const setNotification = ({ type, text }) => {
    switch (type) {
      case notificationTypes.error:
        return toast.error(text);
      case notificationTypes.success:
        return toast.success(text);
      case notificationTypes.warn:
        return toast.warn(text);
      case notificationTypes.info:
        return toast.info(text);
      default:
        return toast.info(text);
    }
  };

  return { setNotification };
}
