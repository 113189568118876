import React from "react";
import { Container } from "react-bootstrap";

import Footer from "../components/footer";
import YoutubePlayer from "../components/youtube";
import Logo from "../images/bfloyd.png";

export default function Home() {
  return (
    <main class="full-height background">
      <Container>
        <div className="pb-4 d-flex flex-row justify-content-center align-items-center">
          <div className="flex-column flex-fill align-items-center">
            <div className="flex-row justify-content-center flex-fill p-4 m-4 align-items-center">
              <img
                src={Logo}
                width="320"
                height="auto"
                className="mx-auto d-block"
                alt="TitleLogo"
              />
            </div>
            <p className="text-center">
              Zes gepassioneerde en doorwinterde muzikanten met jarenlange
              podiumervaring ontdekken dat ze al jaren stiekem een gezamenlijke
              passie koesteren: de tijdloze muziek van Pink Floyd ! De koppen
              werden bij elkaar gestoken en plannen gesmeed. Het basisidee
              groeide, al vrij snel werden de eerste repetities ingepland en na
              een intense periode van hard werken is de band sinds kort
              podiumrijp. <b>B-Floyd</b> is geboren!
            </p>
            <p className="text-center">
              <b>B-Floyd</b> brengt op passende wijze hulde aan de muziek van
              Pink Floyd en neemt u mee op een reis door hun wondere wereld.
              Laat u onderdompelen in de magische sfeer en herbeleef de
              onvergetelijke songs van klassiekers uit o.a. “The Wall” en “Dark
              Side of the Moon”. Wij kijken ernaar uit om u op een van onze
              concerten te mogen ontmoeten en onze muzikale dromen met u te
              mogen delen.
            </p>
            <p className="text-center pb-4">
              <b>B-Floyd</b> brengt op passende wijze hulde aan de muziek van
              Pink Floyd en neemt u mee op een reis door hun wondere wereld.
              Laat u onderdompelen in de magische sfeer en herbeleef de
              onvergetelijke songs van klassiekers uit o.a. “The Wall” en “Dark
              Side of the Moon”. Wij kijken ernaar uit om u op een van onze
              concerten te mogen ontmoeten en onze muzikale dromen met u te
              mogen delen.
            </p>
            <p>
              <div className="text-center pb-1">
                <b>B-Floyd</b> is:
              </div>

              <div className="row pt-1">
                <div className="col-6">
                  <ul>
                    <li>Claude Delatter - Zang</li>
                    <li>Chris Somers - Gitaren</li>
                    <li>
                      {"Jacques 'James' Audenaert - Drums & achtergrondzang"}
                    </li>
                    <li>{"Sven Godyn – Keyboards & Visuals"}</li>
                  </ul>
                </div>
                <div className="col-6">
                  <ul>
                    <li>{"Keyboards & Visuals Stefaan Vansteenkiste"}</li>
                    <li>
                      {"Stefaan Vansteenkiste – Gitaren & achtergrondzang"}
                    </li>
                    <li>{"Filip Vanderputten - Bas"}</li>
                  </ul>
                </div>
              </div>
            </p>
          </div>
        </div>
        <YoutubePlayer videoId="wgDGIB1XmwQ" />
      </Container>

      <Footer />
    </main>
  );
}
