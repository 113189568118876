import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FaFacebook, FaYoutube } from "react-icons/fa";

import Logo from "../../images/logo2.png";

export default function Footer() {
  return (
    <footer className="footer">
      <Container>
        <Row className="smallfs align-items-center">
          <Col
            md={4}
            className="d-flex justify-content-center justify-content-lg-start"
          >
            <img
              src={Logo}
              width="auto"
              height="57"
              className="p-3"
              alt="Logo"
            />
          </Col>
          <Col md={4} className="text-center p-3">
            <div>
              Copyright &copy; {new Date().getFullYear()} - B-floyd - All rights
              reserved
            </div>
          </Col>
          <Col
            md={4}
            className="d-flex justify-content-center justify-content-lg-end"
          >
            <div>
              <a
                href="https://www.facebook.com/bfloydtribute/"
                target="_blank"
                aria-label="Facebook"
                rel="noopener"
              >
                <FaFacebook className="icon" />
              </a>
              <a
                href="https://www.facebook.com/thecrowdcovergroep/"
                target="_blank"
                aria-label="Facebook"
                rel="noopener"
              >
                <FaYoutube className="icon" />
              </a>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}
