export const coockieContainer = {
  background: "#662D91",
  textAlign: "left",
  fontSize: "16px",
  color: "#ced4da",
};

export const coockieButton = {
  color: "#ced4da",
  fontSize: "15px",
  backgroundColor: "black",
  borderRadius: "5px",
};
